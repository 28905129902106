import { memo, useEffect, useState } from 'react';
import { pipe, applyTo, split, head } from 'ramda';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { lightTheme, darkTheme } from 'lib/theme';
import config from '../../config';
import { propTypes, defaultProps, displayName } from 'lib/react';
import {
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import Login from 'views/Login';
import Logout from 'views/Logout';
import { AppContextProvider } from 'context';
// import Discover from 'views/Discover';
import AdminRoute from 'components/AdminRoute';
import Admin from 'views/Admin';
import Landing from 'views/Landing';
// import ExternalRedirect from 'components/ExternalRedirect';
// import Design from 'views/Design';
// import Create from 'views/Create';
// import CommunitySettings from 'views/CommunitySettings';
// import Billing from 'views/Billing';
// import CommunityRouter from 'views/CommunityRouter';
// import JoinViaInvite from 'views/JoinViaInvite';
// import User from 'views/User';
// import Profile from 'views/Profile';
// import Messages from 'views/Messages';
// import Booth from 'views/Booth';
// import Debugging from 'views/Debugging';
// import Signup from 'views/Signup';
// import Community from 'views/Community';
// import Event from 'views/Event';
// import Mobile from 'views/Mobile';
// import Discover from 'views/Discover';

const getSlugFromSubdomain = pipe(
  split('.'),
  head,
);

export default applyTo(() => {
  // const isVanityUrl = useMemo(() => {
  //   return window.location.origin !== `https://${config.BASE_URL}` && window.location.origin !== 'http://localhost:3000';
  // }, []);
  const [theme, setTheme] = useState('light');
  const light = responsiveFontSizes(createTheme(lightTheme));
  const dark = responsiveFontSizes(createTheme(darkTheme));

  const setLightTheme = () => setTheme('light');
  const setDarkTheme = () => setTheme('dark');

  useEffect(() => {
    // vanity urls redirect
    // if /callback do nothing
    if (window.location.pathname === '/callback') {
      return
    }
    if (window.location.origin !== `https://${config.BASE_URL}` && window.location.origin !== 'http://localhost:3000') {
      console.log('>>> vanity url detected')
      const vanitySlug = getSlugFromSubdomain(window.location.host);
      const pathname = window.location.pathname || '';

      if (!['app', 'www'].includes(vanitySlug)) {
        if (vanitySlug === 'admin') {
          return window.location.href = `${window.location.protocol}//${config.BASE_URL}/admin`;
        }
        const INVITE_REGEX = /^\/invite\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
        console.log('>>> invite regex', INVITE_REGEX.test(pathname))
        if (INVITE_REGEX.test(pathname)) {
          // do not include community bits if it's an invite link
          console.log('>>> redirecting to', `${window.location.protocol}//${config.BASE_URL}${pathname}`)
          return window.location.href = `${window.location.protocol}//${config.BASE_URL}${pathname}`
        }
        console.log('>>> redirecting to', `${window.location.protocol}//${config.BASE_URL}/c/${vanitySlug}`)
        return window.location.href = `${window.location.protocol}//${config.BASE_URL}/c/${vanitySlug}`
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme === 'dark' ? dark : light}>

      <AppContextProvider
        theme={theme}
        setDarkTheme={setDarkTheme}
        setLightTheme={setLightTheme}
      >
        <Routes>
          {/* 
            vanity urls (https://<slug>.letsrally.io/) technically render the index route 
            which redirects to login - so only render that index route if it's not a vanity url
          */}
          {/* <Route exact path="/" element={isVanityUrl ? null : <Login />} /> */}
          <Route path='/admin' element={<AdminRoute component={<Admin />} />} />
          {/* Community create routes */}
          {/* <Route path="/create" element={<Create />} />
          <Route path="/invite/:code" element={<JoinViaInvite />} />
          <Route path="/discover" element={<Discover />} />
          <Route path='/billing' element={<AdminRoute component={<Billing />} />} />
          <Route path='/c/:community/settings/:page' element={<CommunitySettings />} />
          <Route path='/community' element={<CommunityRouter />} /> */}

          {/* Auth Routes */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/logout" element={<Logout />} />
          
          <Route path="*" element={<Landing />} />
          {/* <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/debugging" element={<Debugging />} />
          <Route exact path="/mobile" element={<Mobile />} /> */}

          {/* TODO: added these stubs when setting up google oauth which requires them */}
          {/* <Route exact path="/privacy" element={<ExternalRedirect to="https://www.notion.so/rallymotoclub/Rally-Motorcycle-Club-Privacy-Policy-56a2495551554bfe956e294d5cd62ef7" />} />
          <Route exact path="/terms" element={<ExternalRedirect to="https://www.notion.so/rallymotoclub/Rally-Moto-Club-Terms-of-Service-1001fbccd4e2419d80af4cd65f30c514" />} />

          <Route exact path="/design" element={<Design />} /> */}

          {/* Routes with Navigation */}
          {/* <Route path="/u/:username/*" element={<User />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/messages/:message" element={<Messages />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/booth" element={<Booth />} />
          <Route path="/c/:community/*" element={<Community />} />
          <Route path="/e/:event" element={<Event />} />

          <Route path="*" element={<Navigate to="/community" />} /> */}
        </Routes>
      </AppContextProvider>
    </ThemeProvider >
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('App'),
  memo,
));
